<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" align-self="center" class="text-center">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row v-if="enable" dense>
        <v-col cols="12" class="text-center">
          <p>
            Estimado asegurado a continuación se le pide responder si el
            servicio que se menciona se le fue brindado.
          </p>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            <b>Asegurado</b>
            <br />
            <span>{{
              data.insured_name +
              " " +
              data.insured_first_surname +
              " " +
              data.insured_second_surname
            }}</span>
            <br />
            <b>Médico | Proveedor</b>
            <br />
            <span>{{ data.provider_name }}</span>
            <br />
            <b>Fecha de solicitud de servicio</b>
            <br />
            <span>{{ data.letter_date }}</span>
          </p>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn small color="warning" @click="handleSubmit(false)">
            No recibí este servicio
            <v-icon right> mdi-alert </v-icon>
          </v-btn>
          <v-btn small color="success" class="ml-1" @click="handleSubmit(true)">
            Si, Confirmar
            <v-icon right> mdi-check </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!enable">
        <v-col cols="12" class="text-center">
          <h1
            v-text="
              'Ya se ha recibido respuesta para esta confirmación de servicio, puede cerrar esta ventana'
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, msgConfirm, msgAlert } from "../../control";

export default {
  components: {},
  data() {
    return {
      id: atob(this.$route.params.id),
      loading: true,
      data: null,
      enable: true,
    };
  },
  methods: {
    handleSubmit(confirm) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Enviar respuesta "` +
              (confirm ? "Si, Confirmar" : "No recibí este servicio") +
              `"?`
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/public/rss/services/confirm",
              {
                id: this.id,
                confirm: confirm,
                confirm_manual: false,
              },
              {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
              }
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.enable = false;
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(URL_API + "/public/rss/services/confirm/" + this.id, {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }).then((res) => {
      this.data = res.data.data;

      if (this.data.confirm_date) {
        this.enable = false;
      }

      this.loading = false;
    });
  },
};
</script>